import React from "react"
import { B1DarkBlue, H1DarkBlue, medWrapper } from "../../styles/helpers"
import styled from "styled-components"

const PostTitle = ({ title, date, author }) => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  const postDate = new Date(date).toLocaleDateString(undefined, options)
  return (
    <StyledHeader>
      <div className="wrapper">
        <div className="wrapper__inner">
          <h1>{title}</h1>
          <p>{postDate}</p>
          <p>{author}</p>
        </div>
      </div>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  .wrapper {
    ${medWrapper};

    &__inner {
      width: 100%;
      padding-bottom: 3rem;

      h1 {
        ${H1DarkBlue};
        margin-bottom: 3.5rem;
        font-weight: 600;
      }

      p {
        ${B1DarkBlue};
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
`

export default PostTitle
