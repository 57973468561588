import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { B2DarkBlue, Btn1One, medWrapper } from "../../styles/helpers"

const PostNav = ({ data }) => {
  return (
    <PostNavStyled>
      <div className="wrapper">
        <nav>
          {data.prev ? (
            <Link to={`/resources/${data.prev}`}>
              <span>&lt; </span>
              Previous Article
            </Link>
          ) : (
            <button disabled type="button">
              {" "}
              <span>&lt; </span>
              Previous Article
            </button>
          )}

          {/* <Link className="btn-back" to="/resources">
            Resources Page
          </Link> */}
          {data.next ? (
            <Link to={`/resources/${data.next}`}>
              Next Article
              <span>&gt; </span>
            </Link>
          ) : (
            <button disabled type="button">
              {" "}
              Next Article
              <span>&gt; </span>
            </button>
          )}
        </nav>
      </div>
    </PostNavStyled>
  )
}

const PostNavStyled = styled.div`
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .wrapper {
    ${medWrapper};
  }

  nav {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;

    a,
    button {
      ${B2DarkBlue};
      margin: 0 0.5rem;
      border: none;
      background: none;
      font-size: 1.4rem;
      text-transform: uppercase;

      @media (min-width: 768px) {
        ${B2DarkBlue};
      }
    }

    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    a.btn-back {
      ${Btn1One};
      padding: 1rem 0.75rem;
      font-size: 1.4rem;

      @media (min-width: 768px) {
        ${Btn1One};
      }
    }
  }
`

export default PostNav
